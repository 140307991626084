<template>
  <div class="bottom">
    <div class="bottom-box">
      <div>
        <img src="../../assets/bottom/bottomLogo.png"/>
      </div>
      <div class="left">
        <!-- <h1>联系我们</h1> -->
        <h2>CONTACT US</h2>
        <ul>
          <li>{{ $t("Bottom.item1") }}：bd@gzruoyuchen.com</li>
          <li>{{ $t("Bottom.item2") }}：pr@gzruoyuchen.com</li>
          <li>{{ $t("Bottom.item3") }}：hr@gzruoyuchen.com</li>
          <li>{{ $t("Bottom.item4") }}：rycjubao@gzruoyuchen.com</li>
        </ul>
      </div>
      <div class="right">
        <div class="right-son">
          <div>
            <img src="../../assets/bottom/erweima1.png" alt="" />
          </div>
          <div>
            <img src="../../assets/bottom/erweima1.png" alt="" />
          </div>
        </div>
        <div class="right-son">
          <div>
            <h1>020-31959063</h1>
            <!-- <h6>AM9:15~PM06:30 Off-time PM 12:30~PM0 02:00</h6> -->
          </div>
        </div>
      </div>
      <div class="adress">
        <h6>GUANGZHOURUOYUCHEN TECHNOLOGYCO.,LTD</h6>
      </div>
      <div class="beian" @click="baobei">{{ $t("Bottom.item5") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    baobei(){
      window.open('https://beian.miit.gov.cn/');
    }
  }
};
</script>

<style scoped lang="less">
.bottom {
  width: 100%;
  padding: 5% 0 15% 0;
  // max-width: 100%;
  // width: 1920px;
  // height: 291px;
  margin: 0 auto;
  position: relative;
  // background: url("");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  background-color: #f4f4f4;
  &>img{
    width: 100%;
  }
  .bottom-box {
    // width: 1200px;
    width: 62%;
    // height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    padding: 0 .1053rem;
    box-sizing: border-box;
    // background-color: pink;
    margin: 0 auto;
    display: flex;
    .left {
        padding: 0 0 0 .157895rem;
        flex: 5;
      h1{
        font-size: .115789rem;
        margin: .052632rem 0 .010526rem;
        // 
        color: #444444;
      }
      h2{
                color: #444444;
        font-size: .105263rem;
        margin: 0 0 1% 0;
      }
      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        li {
          width: 100%;
        color: #444444;
          margin-bottom: 1%;
          font-size: .084211rem;
        }
        
      }
    }
    .adress{
      position: absolute;
      bottom: -20%;
      h6{
        width: 60%;  
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #b8b8b8;
        // color:  #444444;

      }
    }
    .right {
      flex: 3;
      .right-son:nth-child(1){
        display: flex;
        float: right;
        justify-content: flex-end;
        width: 51%;
      }
      .right-son {
        float: right;
        width: 100%;
        // margin: 15% 0;
        h1{
          width: 100%;
          text-align: right;
          color: #444444 ;
        }
        h6{
          font-size: .042105rem;
          color: #b8b8b8;
          // color:  #444444;
        }
        div {
          text-align: right;
        }
      }
    }
    .beian{
      // display: inline-block;
      // width: 10%;
      
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -.2632rem;
      // top: 0;
      // right: 0;
      text-align: center;
      // margin: auto;
      color: #b8b8b8;
      // color:  #444444;
      font-size: .084211rem;
      cursor: pointer;
    }
  }
}
</style>