module.exports =  {
    header: {
        item1: "首页",
        item2: "关于我们",
        item3: "服务能力 ",
        item4: "自有品牌 ",
        item5: "新闻动态 ",
        item6: "联系我们 ",
        item7: "投资者关系",
    },
    Bottom: {
        item1: "企业合作",
        item2: "媒体合作",
        item3: "简历投递",
        item4: "举报/投诉",
        item5: "Copyright © 粤ICP备14084690号-1",
    },
    home: {
        aboutas:"ABOUT US",
        aboutasTl:"关于我们",
        aboutasTextTl:"若羽臣，领先的全球消费品牌数字化管理公司。",
        aboutasText:"2011 年诞生于广州,以“为全球优质消费品牌链接每个中国消费者”为使命,致力于通过全链路、全渠道的数字化能力，赋能品牌业务的全生命周期增长。",
    },
    Capability: {
        smallTitle: "SERVICE CAPABILITY",
        title: "服务能力",
        itemText1: "全渠道运营",
        itemText2: "数字营销",
        itemText3: "用户运营",
        itemText4: "视觉设计",
        itemText5: "内容种草",
        itemText6: "直播服务",
        itemText7: "数字化能力",
        itemText8: "渠道分销",
        itemText9: "客服管理",
        itemText10: "供应链管理",
    },
    News: {
        smallTitle: "NEWS AND TRENDS",
        title: "新闻动态",
    },
    BrandCon: {
        smallTitle: "COOPERATIVE BRAND",
        title: "合作品牌",
    },
    Aboutus: {
        topsmallTitle: "ABOUT US",
        topTitle: "关于我们",
        smallTitle: "THE ENTERPRISE CULTURE",
        title: "企业文化"
    },
    Introduce: {
        smallTitle: "BRIEF INTRODUCTION",
        title: "公司简介",
        p1: "若羽臣，领先的全球消费品牌数字化管理公司。2011年诞生于广州，以“为全球优质消费品牌链接每个中国消费者”为使命，致力于通过全链路、全渠道的数字化能力，赋能品牌业务的全生命周期增长。",
        p2: "2020年9月25日，若羽臣正式登陆深交所，成为深交所主板代运营公司上市第一股，股票代码003010.SZ，并确立了“广州+上海”的双总部发展格局，累计员工超千人。若羽臣年度GMV超百亿，并保持着近五年年均GMV增幅超50%的高增长，服务半径遍及全球30多个国家和地区，高质量地为宝洁、拜耳、蒙牛等超百个国内外知名品牌提供了数字化服务。",
        p3: "若羽臣已在保健品、母婴、美妆个护、食品饮料等类目建立起头部竞争优势， 并持续向宠物、医疗健康等多个高潜品类拓张。致力于为品牌提供涵盖全渠道精细化运营、数字营销、用户运营、IT技术、客服管理及供应链管理在内的全方位数字化解决方案。若羽臣始终秉承责任第一、拥抱变化、创造价值的企业发展理念， 不断聚焦品牌服务的数字化能力建设，深度赋能品牌的全方位价值提升，共同为中国消费者创造新消费时代下的品质生活。",
        mission: "使命 MISSION",
        missionText: "为全球优质消费品牌链接每个中国消费者",
        vision: "愿景 VISION",
        visionText: "为中国消费者创造新消费时代下的品质生活",
        value: "价值观 VALUES",
        valueText: "责任第一 拥抱变化 创造价值",
    },
    Service: {
        topsmallTitle: "SERVICE CAPABILITY",
        topTitle: "服务能力",
        smallTitle: "SERVICE CAPABILITY",
        showCasesmall: "SERVICE SHOWCASE",
        showCase: "服务案例",
        title: "服务能力",
        item1: "全渠道运营",
        item2: "数字营销",
        item3: "用户运营",
        item4: "直播服务",
        item5: "内容种草",
        item6: "数字化能力",
        item7: "视觉设计",
        item8: "渠道分销",
        item9: "客服管理",
        item10: "供应链管理",
    },
    privateLabel: {
        smallTitle: "PRIVATE BRAND",
        title: "自有品牌",
    },
    theNews: {
        smallTitle: "News",
        title: "新闻动态",
        smallTitle1: "",
        smallTitle2: "",
        title2: "若羽臣新闻",
    },
    Contactus: {
        smallTitle: "CONTACT US",
        title: "联系我们",
        titleson1: "商务合作",
        titleson2: "简历投递",
        smallintrotitle: "CLASS OF 2024 CAMPUS RECRUITMENT JOB INTRODUCTION",
        introtitle: "2024届校园招聘岗位介绍",
        PolarisText: "北极星计划，旨在通过“超巨星养成计划”: 结合内部轮岗、工作实践、1对1导师带教、专业培训等方式,帮助管培生在工作实践中快速成长，培养各岗位上高潜力的新星队伍。",
    },
    Address: {
        smallTitle: "ADDRESS",
        title: "公司地址",
        guangzhou: "广州地址",
        guangzhou1: "广州市天河区高德置地冬广场G座32楼;",
        guangzhou2: "广州市黄埔区大沙东319号保利中誉大厦25楼;",
        guangzhou3: "广州市天河区花城大道68号环球都会广场2407-10单元",
        shanghai: "上海地址",
        shanghai1: "上海虹口区四川北路859号中信广场五楼",
        NewZealand: "新西兰地址",
    },
}