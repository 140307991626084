import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from "vue-router";
import store from './store'
import VueI18n from 'vue-i18n';
import cookie from 'js-cookie';
import './plugins/axios'
import '@/assets/css/resrt.css'
import 'lib-flexible/flexible.js'

import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
// import global from './components/api/api.vue';

// Vue.prototype.COMMON = global

import '@/assets/font/font.css'
Vue.use(ElementUI);

Vue.use(VueI18n);
Vue.prototype.cookie = cookie;

// 设置 cookie 工具函数
Vue.prototype.set_cookie = (name, data, expires) => {
  cookie.set(name, data, {
    expires: expires
  })
}

const i18n = {
  locale: cookie.get('lang') || 'zh', // 语言标识,第一次登录默认是中文
  messages: {
    zh: require('@/assets/language/local_zh.js'), // 汉语
    en: require('@/assets/language/local_en.js'), // 英语
  }
}
console.log("i18n",i18n);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n, // 把 i18n 挂载在全局上
  render: h => h(App)
}).$mount('#app')

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};