import Vue from 'vue'
import Vuex from 'vuex'
import cookie from 'js-cookie';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isZh: cookie.get('lang') != 'en',
  },
  mutations: {
    change( state,value ) {
      // console.log(value);
      if (value == 1) {
        state.isZh = true
      } else {
        state.isZh = false
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
