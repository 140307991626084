<template>
  <div :class="isMobile ? 'journalismConMobile' : 'journalismCon'">
    <!-- <img src="../../assets/home/1-首页_05.jpg" alt="" /> -->
    <div :class="this.Arrinx == 0 ? 'Twobox' : 'box'">
      <div class="journalismCon-title">
        <p>{{ title == 1 ? $t("News.smallTitle") : $t("Aboutus.smallTitle") }}</p>
        <p>{{ title == 1 ? $t("News.title") : $t("Aboutus.title") }}</p>
        <div class="more">
          <img :style="{ width: isMobile ? '.8rem' : '.5421rem' }" @click="$router.push('/news')"
            src="../../assets/about/More.png" alt="" />
        </div>
      </div>

      <div class="box-son">
        <ul v-if="Arr[0].joulist">
          <li v-for="(item, index) in Arr[Arrinx].joulist" :key="index" @click="changeFun(item.index)"
            :class="item.active ? 'active' : ''" style="cursor: pointer">
            <div class="tittle">
              <p>0{{ index + 1 }}</p>
              <p>
                {{ item.title }}
                <span>{{ item.active ? item.diy_time : "" }}</span>
              </p>
            </div>
            <div class="newsBox">
              <img :style="{ width: '92%', height: item.active ? isMobile ? '1.6rem' : '1rem' : '0rem' }"
                :src="PArr === 1 ? item.home_pic : item.pic_url" :alt="item.title" />
            </div>

            <div class="text">
              <div>
                {{ item.detail }}
              </div>
              <div class="MoreTxt" v-if="item.active">
                <img src="../../assets/home/MoreBottom.png" :alt="item.title" />
              </div>
              <div :class="item.active ? 'icon' : 'iconNext'">
                <img :src="next" alt="" />
                <img :src="prev" alt="" />
              </div>
            </div>
          </li>
        </ul>
      </div>

      <!-- 原来的内容  -->
      <!-- 
      <div class="box-son">
        <ul v-if="Arr[0].joulist">
          <li
            v-for="(item, index) in Arr[Arrinx].joulist"
            :key="index"
            @click="changeFun(item.id)"
            :class="item.active ? 'active' : ''"
          >
            <div class="tittle">
              <p>0{{ index + 1 }}</p>
              <p>
                {{ item.title }}
                <span>{{ item.active ? item.create_time : "" }}</span>
              </p>
            </div>
            <div class="newsBox">
              <img
                :style="{ width: '92%', height: item.active ? '1rem' : '0rem' }"
                :src="item.text_img"
                alt=""
              />
            </div>

            <div class="text">
              <div>
                {{ item.describes }}
              </div>
              <div class="MoreTxt" v-if="item.active">
                <img src="../../assets/home/MoreBottom.png" alt="" />
              </div>
              <div :class="item.active ? 'icon' : 'iconNext'">
                <img :src="next" alt="" />
                <img :src="prev" alt="" />
              </div>
            </div>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</template>

<script>
import { findNewsForHome, findCultures } from "../../api/index";
export default {
  props: ["Ptitle", "Pmessage", "PArr"],
  data() {
    return {
      Arrinx: this.PArr,
      title: this.Ptitle,
      message: this.Pmessage,
      isMobile: false,              //判断是pc端还是移动端
      prev: require("../../assets/home/prev.png"),
      next: require("../../assets/home/next.png"),
      url: require("../../assets/home/news.png"),
      // Arr: [
      //   {
      //     joulist: [
      //       {
      //         active: true,
      //         cover: "../../assets/home/news.png",
      //         create_time: "01-20",
      //         describes:
      //           "百节年为首，春节是中华民族最隆重的传统佳节。在春节期间的庆祝活动极为丰富多样，贴年红、大扫除等所有活动，有一个共同的主题——“辞旧迎新”。弘扬传统民俗并融合若羽臣企业特色的设计思路，定制新春礼包。一岁一礼，一寸欢喜。",
      //         id: 1,
      //         source: "若羽臣官网",
      //         text1:
      //           "百节年为首，春节是中华民族最隆重的传统佳节。在春节期间的庆祝活动极为丰富多样，贴年红、大扫除等所有活动，有一个共同的主题——“辞旧迎新”。弘扬传统民俗并融合若羽臣企业特色的设计思路，定制新春礼包。一岁一礼，一寸欢喜。",
      //         text2: "",
      //         text_img: require("../../assets/home/Culture(1).jpg"),
      //         title: "福虎生威，虎气昂昂，开工啦！",
      //       },
      //       {
      //         active: false,
      //         cover:
      //           "https://img2.baidu.com/it/u=3445578133,3485963344&fm=26&fmt=auto",
      //         create_time: "12-25",
      //         describes:
      //           "平安夜，伴随圣诞欢歌与叮咚铃铛，圣诞树亮起荧荧灯光，圣诞老人悄悄走进若羽臣，在每只袜子里塞满礼物，来自陌生人的祝福带给你出其不意的欢喜，整个公司宛如充满惊喜和爱的童话世界。",
      //         id: 2,
      //         source: "若羽臣官网",
      //         text1:
      //           "平安夜，伴随圣诞欢歌与叮咚铃铛，圣诞树亮起荧荧灯光，圣诞老人悄悄走进若羽臣，在每只袜子里塞满礼物，来自陌生人的祝福带给你出其不意的欢喜，整个公司宛如充满惊喜和爱的童话世界。",
      //         text2:
      //           "平安夜，伴随圣诞欢歌与叮咚铃铛，圣诞树亮起荧荧灯光，圣诞老人悄悄走进若羽臣，在每只袜子里塞满礼物，来自陌生人的祝福带给你出其不意的欢喜，整个公司宛如充满惊喜和爱的童话世界。",
      //         text_img: require("../../assets/home/Culture(2).jpg"),
      //         title: "暖冬派对 全「臣」有礼",
      //       },
      //       {
      //         active: false,
      //         cover:
      //           "https://img2.baidu.com/it/u=3445578133,3485963344&fm=26&fmt=auto",
      //         create_time: "12-06",
      //         describes:
      //           "2021年12月4日，我们迎来了“滚烫人生·无所畏惧”双十一表彰暨年陈颁奖大会。2021年的尾声即将来临，回顾今年，我们依旧在不断突破自我，奋勇进发，过程中也创造了越来越多喜人的成绩。其中也不可缺少若羽臣人们全力发挥的专业和优秀，努力付出和全情投入。",
      //         id: 3,
      //         source: "若羽臣官网",
      //         text1:
      //           "今年双十一，从10月20日第一波预售，到11月11日冲刺，战线长达将近一个月的拼搏和战斗中，若羽臣秉持着“以客户为中心，以奋斗者为本”的理念，为品牌客户提供最专业的服务，为品牌销量不断带来新的突破。在这次双十一期间，有许多小伙伴凭借个人及团队的突出表现，获得来自公司的认可，借着表彰大会，给这些优秀的团队与小伙伴进行嘉奖表彰。",
      //         text2:
      //           "据了解，本次评选涵盖广州市10个区，26家机构及企业申报的46个工程项目。专家评委会成员经过对申报单位的实地走访，以及对提报资料的审查等工作，经过半个月细致研判，确定2个直播电商产业聚集区、6个头部直播电商机构、6个有影响力直播电商MCN机构、16个直播电商优秀品牌和8名直播电商带货达人为首批“直播电商工程”入选单位。若羽臣作为6家入选的头部直播电商机构之一，充分体现了参评专家组对于若羽臣电商直播服务能力的认可。",
      //         text_img: require("../../assets/home/Culture(3).jpg"),
      //         title: "滚烫人生 无所畏惧",
      //       },
      //       {
      //         active: false,
      //         cover:
      //           "https://img2.baidu.com/it/u=3445578133,3485963344&fm=26&fmt=auto",
      //         create_time: "09-22",
      //         describes:
      //           "有人说年轻人对传统节日已经不感兴趣了，觉得只是视觉符号和形式，找个理由来聚会。弘扬民族文化，传承民族精神，过好中国传统节日，RYC打造了一个传统与潮流相结合——专属于年轻人的中秋，整一个最最最最最潮的中秋节，猜最潮的灯谜，玩最潮的游戏！",
      //         id: 4,
      //         source: "若羽臣官网",
      //         text1:
      //           "据悉，大会由中国最具影响力的母婴产业平台、母婴行业领域垂直媒体母婴行业观察主办。自2014年成立以来，母婴行业观察已发展成国内母婴行业风向标内容产业平台、智库，专注在母婴商业领域的创业创新。而其主办的未来母婴大会，也成为国内母婴人一年一度的重磅盛会。凭借在母婴领域深耕细作的运营能力沉淀，以及合作品牌对服务能力的充分认可，若羽臣在本次大会上将2021年度最佳母婴TP服务商称号收入囊中，再次巩固了若羽臣在母婴行业品牌电商服务赛道的优势地位。",
      //         text2:
      //           "会议指出,按照党中央、国务院部署,相关部门积极推进通关便利化,取得明显成效\n截至今年5月,进口和出口整体通关时间已提前实现比2017年压缩一半的目标,通关成本显\n著降低。下一步,要按照推动更高水平对外开放的要求,一是继续简化一体化通关流程,实\n施进口概要申报、完整申报“两步申报”通关模式改革,大幅压缩通关时间。年底前做到国\n际贸易“单一窗口”对主要业务应用率达到100%。二是进一步简化进出口环节监管证件,年\n底前对除安全保密等特殊情况外的监管证件,提前一年全部实现网上申报、网上办理。三是\n各地要抓紧公布口岸经营服务企业操作时限标准,提高口岸作业效率。强化口岸收费目录清\n单管理,落实降费措施,兑现承诺,强化责任,并依法查处垄断收费行为。",
      //         text_img: require("../../assets/home/Culture(4).png"),
      //         title: "月夜越YOUNG",
      //       },
      //     ],
      //   },
      //   {
      //     joulist: [
      //       {
      //         active: true,
      //         cover: "../../assets/home/news.png",
      //         create_time: "01-21",
      //         describes:
      //           "2022年1月18日，若羽臣与COSTA正式签署了战略合作协议，若羽臣创始人兼董事长王玉与COSTA中国总经理欧阳庆球出席了仪式现场，双方宣布将在电商渠道拓展、数字营销及消费者运营等领域展开深度合作，共同打造咖啡电商合作新格局。",
      //         id: 1,
      //         source: "若羽臣官网",
      //         text1:
      //           "根据合作协议，若羽臣将围绕店铺运营、营销推广、消费者运营等方面，为COSTA提供高质量、专业化的全链路营销服务，全面支持COSTA在电商渠道的战略规划，赋能COSTA销售业绩的增长。",
      //         text2:
      //           "欧阳庆球表示，COSTA已经制定了明确的发展策略，除即饮咖啡、自助咖啡机、线下门店等领域外，未来还将重点发力快消咖啡这一赛道。目前，在即饮咖啡领域，COSTA已实现迅猛增长的好势头。他相信，在若羽臣的帮助下，COSTA将持续聚焦家用咖啡并大力拓展电商渠道，不断拉近与消费者的距离，进一步扩大市场份额。",
      //         text_img: require("../../assets/home/COSTA (1).jpg"),
      //         title: "若羽臣与COSTA达成战略合作 共同打造咖啡电商新格局",
      //       },
      //       {
      //         active: false,
      //         cover:
      //           "https://img2.baidu.com/it/u=3445578133,3485963344&fm=26&fmt=auto",
      //         create_time: "01-19",
      //         describes:
      //           "日前，天猫重磅发布“2021 年下半年天猫星级服务商榜单”，面向全球优质消费品牌的电子商务综合服务提供商若羽臣，凭借过硬的综合运营服务实力再获“五星服务商”认证。",
      //         id: 2,
      //         source: "若羽臣官网",
      //         text1:
      //           "日前，天猫重磅发布“2021 年下半年天猫星级服务商榜单”，面向全球优质消费品牌的电子商务综合服务提供商若羽臣，凭借过硬的综合运营服务实力再获“五星服务商”认证。",
      //         text2:
      //           "日前，天猫重磅发布“2021 年下半年天猫星级服务商榜单”，面向全球优质消费品牌的电子商务综合服务提供商若羽臣，凭借过硬的综合运营服务实力再获“五星服务商”认证。",
      //         text_img: require("../../assets/home/COSTA(4).jpg"),
      //         title: "连续获评！若羽臣再度上榜「2021 下半年天猫五星服务商」",
      //       },
      //       {
      //         active: false,
      //         cover:
      //           "https://img2.baidu.com/it/u=3445578133,3485963344&fm=26&fmt=auto",
      //         create_time: "01-17",
      //         describes:
      //           "近日，以“树立直播电商行业发展标杆，助力打造直播电商之都”为目标的，广州市首批直播电商“个十百千万”工程评选结果正式公布。若羽臣背靠十余年沉淀的一站式综合电商服务能力，以出色的直播电商服务荣膺广州市“头部直播电商机构”称号。",
      //         id: 3,
      //         source: "若羽臣官网",
      //         text1:
      //           "“个十百千万”工程评选工作是依据《广州市直播电商发展行动方案（2020-2022年）》的发展目标，由广州市商务局牵头，在深入调研的基础上，组织各市直单位、广州直播电商研究院、广州市直播电子商务行业协会，以及其他行业商协会、重点企业，经过多轮研究，公开征求社会意见后，责成广州市直播电子商务行业协会发布的行业体系认证标准。",
      //         text2:
      //           "据了解，本次评选涵盖广州市10个区，26家机构及企业申报的46个工程项目。专家评委会成员经过对申报单位的实地走访，以及对提报资料的审查等工作，经过半个月细致研判，确定2个直播电商产业聚集区、6个头部直播电商机构、6个有影响力直播电商MCN机构、16个直播电商优秀品牌和8名直播电商带货达人为首批“直播电商工程”入选单位。若羽臣作为6家入选的头部直播电商机构之一，充分体现了参评专家组对于若羽臣电商直播服务能力的认可。",
      //         text_img: require("../../assets/home/COSTA (2).jpg"),
      //         title: "若羽臣入选 “个十百千万”工程 荣膺“头部直播电商机构”称号",
      //       },
      //       {
      //         active: false,
      //         cover:
      //           "https://img2.baidu.com/it/u=3445578133,3485963344&fm=26&fmt=auto",
      //         create_time: "01-11",
      //         describes:
      //           "1月11日，以“精进的力量”为主题的2021第七届未来母婴大会暨樱桃大赏年度盛典落下帷幕，头部电商综合运营服务商若羽臣荣膺最佳母婴TP服务商称号。",
      //         id: 4,
      //         source: "若羽臣官网",
      //         text1:
      //           "据悉，大会由中国最具影响力的母婴产业平台、母婴行业领域垂直媒体母婴行业观察主办。自2014年成立以来，母婴行业观察已发展成国内母婴行业风向标内容产业平台、智库，专注在母婴商业领域的创业创新。而其主办的未来母婴大会，也成为国内母婴人一年一度的重磅盛会。凭借在母婴领域深耕细作的运营能力沉淀，以及合作品牌对服务能力的充分认可，若羽臣在本次大会上将2021年度最佳母婴TP服务商称号收入囊中，再次巩固了若羽臣在母婴行业品牌电商服务赛道的优势地位。",
      //         text2:
      //           "会议指出,按照党中央、国务院部署,相关部门积极推进通关便利化,取得明显成效\n截至今年5月,进口和出口整体通关时间已提前实现比2017年压缩一半的目标,通关成本显\n著降低。下一步,要按照推动更高水平对外开放的要求,一是继续简化一体化通关流程,实\n施进口概要申报、完整申报“两步申报”通关模式改革,大幅压缩通关时间。年底前做到国\n际贸易“单一窗口”对主要业务应用率达到100%。二是进一步简化进出口环节监管证件,年\n底前对除安全保密等特殊情况外的监管证件,提前一年全部实现网上申报、网上办理。三是\n各地要抓紧公布口岸经营服务企业操作时限标准,提高口岸作业效率。强化口岸收费目录清\n单管理,落实降费措施,兑现承诺,强化责任,并依法查处垄断收费行为。",
      //         text_img: require("../../assets/home/COSTA (3).jpg"),
      //         title: "精进的力量 若羽臣荣膺“最佳母婴TP服务商”称号",
      //       },
      //     ],
      //   },
      // ],
      Arr: [{ joulist: [] }, { joulist: [] }],
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    //判断是pc还是移动端
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    console.log('this.isMobile', this.isMobile);
    console.log("this.title", this.title);
  },
  methods: {
    // renderTime(date) {
    //   console.log("date-=--------:", date);
    //   const dateee = new Date(date).toJSON();
    //   console.log("dateee:", dateee);
    //   let d = new Date(new Date(dateee) + 8 * 3600 * 1000);
    //   console.log("d:", d);
    //   let Mouth1 =
    //     d.getMonth() + 1 >= 10 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1);
    //   let Date1 = d.getDate() >= 10 ? d.getDate() : "0" + d.getDate();
    //   return Mouth1 + "-" + Date1;
    //   // d=((d.getMonth() + 1) >=10 ?(d.getMonth() + 1):'0'+(d.getMonth() + 1)) + '-' + d.getDate()>=10?d.getDate():'0'+d.getDate();
    //   // return new Date(+new Date(dateee) + 8 * 3600 * 1000)
    //   //   .toISOString()
    //   //   .replace(/T/g, " ")
    //   //   .replace(/\.[\d]{3}Z/, "");
    // },
    renderTime(date) {
      const dateee = new Date(date)
      // const Year1 = dateee.getFullYear()
      const Mouth1 = dateee.getMonth() + 1 >= 10 ? dateee.getMonth() + 1 : '0' + (dateee.getMonth() + 1)
      const Date1 = dateee.getDate() >= 10 ? dateee.getDate() : '0' + dateee.getDate()
      return Mouth1 + '-' + Date1
    },
    getList() {
      if (this.PArr === 1) {
        findNewsForHome().then((res) => {
          let data = res.data;
          data.map((v, i) => {
            // console.log(typeof v.diy_time,' v.diy_time', v.diy_time)
            // console.log(v.hasOwnProperty('diy_time'))
            v.diy_time = v.diy_time != null && v.diy_time != '' ? this.renderTime(v.diy_time) : this.renderTime(v.create_time)
            // v.diy_time = (v.diy_time != null && v.diy_time != ''? this.renderTime(v.v.diy_time): this.renderTime(v.create_time))
            v.index = i;
            v.active = false;
            i == 0 && (v.active = true);
          });
          // console.log('保存出来的数据:',data)
          this.Arr[this.PArr].joulist = data;
        });
      } else if (this.PArr === 0) {
        findCultures().then((res) => {
          let data = res.data;
          data.map((v, i) => {
            // v.update_time = this.renderTime(v.update_time);
            // v.create_time = this.renderTime(v.create_time);
            // v.diy_time = v.diy_time
            //   ? this.renderTime(v.v.diy_time)
            //   : this.renderTime(v.create_time);
            v.diy_time = v.diy_time != null && v.diy_time != '' ? this.renderTime(v.diy_time) : this.renderTime(v.create_time)
            v.index = i;
            v.active = false;
            i == 0 && (v.active = true);
          });
          // console.log('保存出来的数据:',data)
          this.Arr[this.PArr].joulist = data;
        });
        // console.log('企业文化')
      }
    },
    nav() {
      // this.$router.push({
      //   name: "newdetail",
      //   query: {
      //     id: e,
      //   },
      // });
      // if (e == "01-21") {
      //   window.open("https://mp.weixin.qq.com/s/NYsBcC66_catlSvvT-1toA");
      // } else if (e == "01-19") {
      //   window.open("https://mp.weixin.qq.com/s/d0BR1oHCO1lDYu6DifUNqg");
      // } else if (e == "01-17") {
      //   window.open("https://mp.weixin.qq.com/s/MKa8R2zu0NXXGC-8IRn-PQ");
      // } else if (e == "01-11") {
      //   window.open("https://mp.weixin.qq.com/s/9R1FFZ4J1MsGfJ9QqtQJ4Q");
      // }
    },
    CultureNav() {
      //  this.$router.push({
      //   name: "Culturedetail",
      //   query: {
      //     id: e,
      //   },
      // });
      // if (e == "01-20") {
      //   window.open("https://mp.weixin.qq.com/s/6YWDIppzxAeBu6aKucBl7A");
      // } else if (e == "12-25") {
      //   window.open("https://mp.weixin.qq.com/s/0T1YbsdsdalUaYwBTgDIYA");
      // } else if (e == "12-06") {
      //   window.open("https://mp.weixin.qq.com/s/G_5PoGrNbkjEB0SJWcbCeA");
      // } else if (e == "09-22") {
      //   window.open("https://mp.weixin.qq.com/s/MiWOmciLPRtHVNIunN6vvg");
      // }
    },
    changeFun(e) {
      let index = e;
      if (this.Arr[this.Arrinx].joulist[index].active) {
        let item = this.Arr[this.Arrinx].joulist[index];
        let navE = this.Arr[this.Arrinx].joulist[index].create_time;
        console.log(navE, item);
        if (this.$route.fullPath == "/about") {
          window.open(item.out_url);
          // this.CultureNav(navE);
        } else {
          window.open(item.out_url);
          // this.nav(navE);
        }
      } else {
        for (let i = 0; i < this.Arr[this.Arrinx].joulist.length; i++) {
          this.Arr[this.Arrinx].joulist[i].active = false;
        }
        this.Arr[this.Arrinx].joulist[index].active = true;
      }
    },
  },
};
</script>

<style scoped lang="less">
.journalismCon {
  width: 100%;
  height: 4.207895rem;
  position: relative;

  >img {
    width: 100%;
  }

  .box {
    width: 63%;
    height: 100%;
    // background-color: rgba(200, 200, 200, 0.5);
    margin: auto;

    .tittle {
      margin: 0 auto;
      text-align: left;
      width: 92%;

      // padding: 9% 0 0 0;
      p:nth-child(1) {
        padding: 0.1053rem 0 0 0;
        text-align: left;
        font-size: .2105rem;
        // font-size: 32px;
        line-height: 80%;
      }

      p:nth-child(2) {
        // height: .368421rem;
        padding: 0 0 1% 0;
        text-align: left;
        font-size: 0.1684rem;
        // font-size: 32px;
        overflow: hidden;
        display: -webkit-box;
        color: #444444;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        span {
          font-size: .1053rem;
          // font-size: 16px;
        }
      }
    }

    .newsBox {
      margin: 0.1053rem auto;
      text-align: center;

      img {
        width: 92%;
      }
    }

    .journalismCon-title {
      width: 90%;
      margin: 5% auto;
      position: relative;

      p:nth-child(2) {
        font-size: .2105rem;
        // font-size: 45px;
        // font-weight: 500;
        // color: rgb(62, 62, 62);
        color: #444444;
      }

      p:nth-child(1) {
        font-size: .1053rem;
        // font-size: 16px;
        // color: #b8b8b8;
        color: #b8b8b8;
        // margin-top:
      }

      .more {
        width: 11%;
        position: absolute;
        right: 0%;
        // top: 10%;
        bottom: 0%;
        cursor: pointer;

        img {
          width: 0.5421rem;
        }
      }
    }

    // .more {
    //   position: absolute;
    //   right: 9%;
    //   top: 10%;
    //   cursor: pointer;
    // }
    .box-son {
      .active {
        .tittle {
          padding: 0 0 0 0;
          position: relative;
        }

        p:nth-child(1) {
          opacity: 0.6;
          padding: 0.1053rem 0 0 0;
          text-align: left;
          font-size: .2105rem;
          // font-size: 32px;
          line-height: 80%;
        }

        p:nth-child(2) {
          width: 80%;
          // height: .368421rem;
          padding: 0 0 1% 0;
          text-align: left;
          font-size: 0.1684rem;
          // font-size: 32px;
          overflow: hidden;
          display: -webkit-box;
          color: #fff;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          span {
            font-size: .1053rem;
            // font-size: 16px;
            position: absolute;
            right: 0;
            bottom: 7%;
            // left: 30%;
            // margin-right: 0;
          }
        }
      }

      width: 90%;
      //   background-color: pink;
      margin: 2% auto 0;

      ul {
        width: 100%;
        display: flex;
        // flex-wrap: wrap;
        justify-content: space-between;
        // padding: 10px;
        border-left: thin solid #cdcdcd;
        box-sizing: border-box;

        .active {
          transition: 0.8s;
          width: 55%;
          color: #ffffff;
          background-color: #009bdd;
        }

        li {
          height: 2.789474rem;
          transition: 0.8s;
          width: 15%;
          border-top: thin solid #cdcdcd;
          border-right: thin solid #cdcdcd;
          border-bottom: thin solid #cdcdcd;
          //   margin-right: 35px;
          color: #cdcdcd;
          background-color: #ffffff;
          position: relative;

          div:first-child {
            text-align: center;

            img {
              width: 90%;
              margin: 0.078947rem 0 0 0;
            }
          }

          .text {
            padding: 0% 5% 0% 5%;
            text-align: left;

            .MoreTxt {
              position: absolute;
              left: 5%;

              img {
                width: 0.6158rem;
                height: 0.2105rem;
              }
            }

            div {
              text-align: left;
              margin: 0.0526rem 0 0 0;
              font-size: .1053rem;
              // font-size: 16px;
              line-height: 180%;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }

          .icon {
            height: 0.1105rem;
            width: 13%;
            position: absolute;
            right: 0.2%;
            bottom: 6%;

            img {
              position: absolute;
              width: 0.1211rem;
              height: 0.1105rem;
            }

            img:nth-child(1) {
              opacity: 0;
            }
          }

          .iconNext {
            height: 0.1105rem;
            width: 20%;
            position: absolute;
            right: 40%;
            bottom: 6%;

            img {
              position: absolute;
              width: 0.1211rem;
              height: 0.1105rem;
            }

            img:nth-child(2) {
              opacity: 0;
            }
          }
        }

        li:hover {
          color: #ffffff;
          background-color: #009bdd;
        }

        li:hover>.tittle>p:nth-child(1) {
          color: #ffffff;
          opacity: 0.6;
        }

        li:hover>.tittle>p:nth-child(2) {
          color: #ffffff;
        }

        li:hover>.text>.iconNext {
          height: 0.1105rem;
          width: 20%;
          position: absolute;
          right: 40%;
          bottom: 6%;

          img {
            position: absolute;
            width: 0.1211rem;
            height: 0.1105rem;
          }

          img:nth-child(1) {
            opacity: 0;
          }

          img:nth-child(2) {
            opacity: 1;
          }
        }
      }
    }
  }

  .Twobox {
    width: 63%;
    height: 100%;
    padding: 0 0.105263rem;
    margin: auto;

    .tittle {
      margin: 0 auto;
      text-align: left;
      width: 92%;

      // padding: 9% 0 0 0;
      p:nth-child(1) {
        padding: 0.1053rem 0 0 0;
        text-align: left;
        font-size: .2105rem;
        // font-size: 32px;
        line-height: 80%;
      }

      p:nth-child(2) {
        // height: .368421rem;
        padding: 0 0 1% 0;
        text-align: left;
        font-size: 0.1684rem;
        // font-size: 32px;
        overflow: hidden;
        display: -webkit-box;
        color: #444444;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        span {
          line-height: 0.105263rem;
          // font-size: 16px;
        }
      }
    }

    .newsBox {
      margin: 0.1053rem auto;
      text-align: center;

      img {
        width: 92%;
      }
    }

    .journalismCon-title {
      width: 100%;
      margin: 5% auto;
      position: relative;

      p:nth-child(2) {
        font-size: .2105rem;
        // font-size: 45px;
        // font-weight: 500;
        // color: rgb(62, 62, 62);
        color: #444444;
      }

      p:nth-child(1) {
        font-size: .1053rem;
        // font-size: 16px;
        // color: #b8b8b8;
        color: #b8b8b8;
        // margin-top:
      }

      .more {
        width: 11%;
        position: absolute;
        right: 0%;
        // top: 10%;
        bottom: 0%;
        cursor: pointer;

        img {
          width: 0.5421rem;
        }
      }
    }

    // .more {
    //   position: absolute;
    //   right: 9%;
    //   top: 10%;
    //   cursor: pointer;
    // }
    .box-son {
      .active {
        .tittle {
          padding: 0 0 0 0;
          position: relative;
        }

        p:nth-child(1) {
          opacity: 0.6;
          padding: 0.1053rem 0 0 0;
          text-align: left;
          font-size: .2105rem;
          // font-size: 32px;
          line-height: 80%;
        }

        p:nth-child(2) {
          width: 80%;
          // height: .368421rem;
          padding: 0 0 1% 0;
          text-align: left;
          font-size: 0.1684rem;
          // font-size: 32px;

          overflow: hidden;
          display: -webkit-box;
          color: #fff;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          span {
            line-height: 0.105263rem;
            // font-size: 16px;
            position: absolute;
            right: 0;
            bottom: 7%;
            // left: 30%;
            // margin-right: 0;
          }
        }
      }

      width: 100%;
      //   background-color: pink;
      margin: 2% auto 0;

      ul {
        width: 100%;
        display: flex;
        // flex-wrap: wrap;
        justify-content: space-between;
        // padding: 10px;
        border-left: thin solid #cdcdcd;
        box-sizing: border-box;

        .active {
          transition: 0.8s;
          width: 55%;
          color: #ffffff;
          background-color: #009bdd;
        }

        li {
          height: 3.189474rem;
          transition: 0.8s;
          width: 15%;
          border-top: thin solid #cdcdcd;
          border-right: thin solid #cdcdcd;
          border-bottom: thin solid #cdcdcd;
          //   margin-right: 35px;
          color: #cdcdcd;
          background-color: #ffffff;
          position: relative;

          div:first-child {
            text-align: center;

            img {
              width: 90%;
              margin: 0.078947rem 0 0 0;
            }
          }

          .text {
            padding: 0% 5% 0% 5%;
            text-align: left;

            .MoreTxt {
              position: absolute;
              left: 5%;

              img {
                width: 0.6158rem;
                height: 0.2105rem;
              }
            }

            div {
              text-align: left;
              margin: 0.0526rem 0 0 0;
              font-size: .1053rem;
              // font-size: 16px;
              line-height: 180%;
              overflow: hidden;

              display: -webkit-box;

              -webkit-line-clamp: 3;

              -webkit-box-orient: vertical;
            }
          }

          .icon {
            height: 0.1105rem;
            width: 13%;
            position: absolute;
            right: 0.2%;
            bottom: 6%;

            img {
              position: absolute;
              width: 0.1211rem;
              height: 0.1105rem;
            }

            img:nth-child(1) {
              opacity: 0;
            }
          }

          .iconNext {
            height: 0.1105rem;
            width: 20%;
            position: absolute;
            right: 40%;
            bottom: 6%;

            img {
              position: absolute;
              width: 0.1211rem;
              height: 0.1105rem;
            }

            img:nth-child(2) {
              opacity: 0;
            }
          }
        }

        li:hover {
          color: #ffffff;
          background-color: #009bdd;
        }

        li:hover>.tittle>p:nth-child(1) {
          color: #ffffff;
          opacity: 0.6;
        }

        li:hover>.tittle>p:nth-child(2) {
          color: #ffffff;
        }

        li:hover>.text>.iconNext {
          height: 0.1105rem;
          width: 20%;
          position: absolute;
          right: 40%;
          bottom: 6%;

          img {
            position: absolute;
            width: 0.1211rem;
            height: 0.1105rem;
          }

          img:nth-child(1) {
            opacity: 0;
          }

          img:nth-child(2) {
            opacity: 1;
          }
        }
      }
    }
  }
}

// 移动端的样式
.journalismConMobile {
  width: 100%;
  height: 5.207895rem;
  position: relative;

  >img {
    width: 100%;
  }

  .box {
    // width: 63%;
    height: 100%;
    // background-color: rgba(200, 200, 200, 0.5);
    margin: auto;

    .tittle {
      margin: 0 auto;
      text-align: left;
      width: 92%;

      // padding: 9% 0 0 0;
      p:nth-child(1) {
        padding: 0.1053rem 0 0 0;
        text-align: left;
        font-size: 32px;
        line-height: 80%;
      }

      p:nth-child(2) {
        // height: .368421rem;
        padding: 0 0 1% 0;
        text-align: left;
        // font-size: .2105rem;
        font-size: 32px;
        overflow: hidden;
        display: -webkit-box;
        color: #444444;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        span {
          font-size: 16px;
        }
      }
    }

    .newsBox {
      margin: 0.1053rem auto;
      text-align: center;

      img {
        width: 92%;
      }
    }

    .journalismCon-title {
      width: 80%;
      margin: 5% auto;
      position: relative;

      p:nth-child(2) {
        font-size: .2105rem;
        // font-size: 45px;
        // font-weight: 500;
        // color: rgb(62, 62, 62);
        color: #444444;
      }

      p:nth-child(1) {
        font-size: .1053rem;
        // font-size: 16px;
        // color: #b8b8b8;
        color: #b8b8b8;
        // margin-top:
      }

      .more {
        width: 11%;
        position: absolute;
        right: 0%;
        // top: 10%;
        bottom: 0%;
        cursor: pointer;

        img {
          width: 0.5421rem;
        }
      }
    }

    // .more {
    //   position: absolute;
    //   right: 9%;
    //   top: 10%;
    //   cursor: pointer;
    // }
    .box-son {
      .active {
        .tittle {
          padding: 0 0 0 0;
          position: relative;
        }

        p:nth-child(1) {
          opacity: 0.6;
          padding: 0.1053rem 0 0 0;
          text-align: left;
          font-size: 0.2105rem;
          // font-size: 32px;
          line-height: 80%;
        }

        p:nth-child(2) {
          width: 80%;
          // height: .368421rem;
          padding: 0 0 1% 0;
          text-align: left;
          // font-size: .2105rem;
          font-size: 0.1684rem;
          // font-size: 32px;
          overflow: hidden;
          display: -webkit-box;
          color: #fff;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          span {
            font-size: .1053rem;
            // font-size: 16px;
            position: absolute;
            right: 0;
            bottom: 7%;
            // left: 30%;
            // margin-right: 0;
          }
        }
      }

      // width: 90%;
      //   background-color: pink;
      margin: 2% auto 0;

      ul {
        width: 80%;
        margin: 0 auto;
        display: flex;
        // flex-wrap: wrap;
        justify-content: space-between;
        // padding: 10px;
        border-left: thin solid #cdcdcd;
        box-sizing: border-box;

        .active {
          transition: 0.8s;
          width: 55%;
          color: #ffffff;
          background-color: #009bdd;
        }

        li {
          height: 3.659474rem;
          transition: 0.8s;
          width: 15%;
          border-top: thin solid #cdcdcd;
          border-right: thin solid #cdcdcd;
          border-bottom: thin solid #cdcdcd;
          //   margin-right: 35px;
          color: #cdcdcd;
          background-color: #ffffff;
          position: relative;

          div:first-child {
            text-align: center;

            img {
              width: 90%;
              margin: 0.078947rem 0 0 0;
            }
          }

          .text {
            padding: 0% 5% 0% 5%;
            text-align: left;

            .MoreTxt {
              position: absolute;
              left: 5%;

              img {
                width: 0.6158rem;
                height: 0.2105rem;
              }
            }

            div {
              text-align: left;
              margin: 0.0526rem 0 0 0;
              font-size: 16px;
              line-height: 180%;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }

          .icon {
            height: 0.1105rem;
            width: 13%;
            position: absolute;
            right: 0.2%;
            bottom: 6%;

            img {
              position: absolute;
              width: 0.1211rem;
              height: 0.1105rem;
            }

            img:nth-child(1) {
              opacity: 0;
            }
          }

          .iconNext {
            height: 0.1105rem;
            width: 20%;
            position: absolute;
            right: 40%;
            bottom: 6%;

            img {
              position: absolute;
              width: 0.1211rem;
              height: 0.1105rem;
            }

            img:nth-child(2) {
              opacity: 0;
            }
          }
        }

        li:hover {
          color: #ffffff;
          background-color: #009bdd;
        }

        li:hover>.tittle>p:nth-child(1) {
          color: #ffffff;
          opacity: 0.6;
        }

        li:hover>.tittle>p:nth-child(2) {
          color: #ffffff;
        }

        li:hover>.text>.iconNext {
          height: 0.1105rem;
          width: 20%;
          position: absolute;
          right: 40%;
          bottom: 6%;

          img {
            position: absolute;
            width: 0.1211rem;
            height: 0.1105rem;
          }

          img:nth-child(1) {
            opacity: 0;
          }

          img:nth-child(2) {
            opacity: 1;
          }
        }
      }
    }
  }

  .Twobox {
    width: 80%;
    height: 100%;
    padding: 0 0.105263rem;
    margin: auto;

    .tittle {
      margin: 0 auto;
      text-align: left;
      width: 92%;

      // padding: 9% 0 0 0;
      p:nth-child(1) {
        padding: 0.1053rem 0 0 0;
        text-align: left;
        font-size: 32px;
        line-height: 80%;
      }

      p:nth-child(2) {
        // height: .368421rem;
        padding: 0 0 1% 0;
        text-align: left;
        font-size: 32px;
        overflow: hidden;
        display: -webkit-box;
        color: #444444;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        span {
          font-size: 16px;
        }
      }
    }

    .newsBox {
      margin: 0.1053rem auto;
      text-align: center;

      img {
        width: 92%;
      }
    }

    .journalismCon-title {
      width: 100%;
      margin: 5% auto;
      position: relative;

      p:nth-child(2) {
        font-size: .2105rem;
        // font-size: 45px;
        // font-weight: 500;
        // color: rgb(62, 62, 62);
        color: #444444;
      }

      p:nth-child(1) {
        font-size: .1053rem;
        // font-size: 16px;
        // color: #b8b8b8;
        color: #b8b8b8;
        // margin-top:
      }

      .more {
        width: 11%;
        position: absolute;
        right: 0%;
        // top: 10%;
        bottom: 0%;
        cursor: pointer;

        img {
          width: 0.5421rem;
        }
      }
    }

    // .more {
    //   position: absolute;
    //   right: 9%;
    //   top: 10%;
    //   cursor: pointer;
    // }
    .box-son {
      .active {
        .tittle {
          padding: 0 0 0 0;
          position: relative;
        }

        p:nth-child(1) {
          opacity: 0.6;
          padding: 0.1053rem 0 0 0;
          text-align: left;
          font-size: 0.2105rem;
          // font-size: 32px;
          line-height: 80%;
        }

        p:nth-child(2) {
          width: 80%;
          // height: .368421rem;
          padding: 0 0 1% 0;
          text-align: left;
          font-size: 0.1684rem;
          // font-size: 32px;

          overflow: hidden;
          display: -webkit-box;
          color: #fff;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          span {
            font-size: 0.1684rem;
            // font-size: 16px;
            position: absolute;
            right: 0;
            bottom: 7%;
            // left: 30%;
            // margin-right: 0;
          }
        }
      }

      width: 100%;
      //   background-color: pink;
      margin: 2% auto 0;

      ul {
        width: 100%;
        display: flex;
        // flex-wrap: wrap;
        justify-content: space-between;
        // padding: 10px;
        border-left: thin solid #cdcdcd;
        box-sizing: border-box;

        .active {
          transition: 0.8s;
          width: 55%;
          color: #ffffff;
          background-color: #009bdd;
        }

        li {
          height: 3.189474rem;
          transition: 0.8s;
          width: 15%;
          border-top: thin solid #cdcdcd;
          border-right: thin solid #cdcdcd;
          border-bottom: thin solid #cdcdcd;
          //   margin-right: 35px;
          color: #cdcdcd;
          background-color: #ffffff;
          position: relative;

          div:first-child {
            text-align: center;

            img {
              width: 90%;
              margin: 0.078947rem 0 0 0;
            }
          }

          .text {
            padding: 0% 5% 0% 5%;
            text-align: left;

            .MoreTxt {
              position: absolute;
              left: 5%;

              img {
                width: 0.6158rem;
                height: 0.2105rem;
              }
            }

            div {
              text-align: left;
              margin: 0.0526rem 0 0 0;
              font-size: 16px;
              line-height: 180%;
              overflow: hidden;

              display: -webkit-box;

              -webkit-line-clamp: 3;

              -webkit-box-orient: vertical;
            }
          }

          .icon {
            height: 0.1105rem;
            width: 13%;
            position: absolute;
            right: 0.2%;
            bottom: 6%;

            img {
              position: absolute;
              width: 0.1211rem;
              height: 0.1105rem;
            }

            img:nth-child(1) {
              opacity: 0;
            }
          }

          .iconNext {
            height: 0.1105rem;
            width: 20%;
            position: absolute;
            right: 40%;
            bottom: 6%;

            img {
              position: absolute;
              width: 0.1211rem;
              height: 0.1105rem;
            }

            img:nth-child(2) {
              opacity: 0;
            }
          }
        }

        li:hover {
          color: #ffffff;
          background-color: #009bdd;
        }

        li:hover>.tittle>p:nth-child(1) {
          color: #ffffff;
          opacity: 0.6;
        }

        li:hover>.tittle>p:nth-child(2) {
          color: #ffffff;
        }

        li:hover>.text>.iconNext {
          height: 0.1105rem;
          width: 20%;
          position: absolute;
          right: 40%;
          bottom: 6%;

          img {
            position: absolute;
            width: 0.1211rem;
            height: 0.1105rem;
          }

          img:nth-child(1) {
            opacity: 0;
          }

          img:nth-child(2) {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>