import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue'),
    children: [{
      path: '/Culturedetail',
      name: 'Culturedetail',
      component: () => import(/* webpackChunkName: "about" */ '../views/Culturedetail.vue'),
    }, {
      path: '/Aboutus',
      name: 'Aboutus',
      component: () => import(/* webpackChunkName: "about" */ '../views/Aboutus.vue'),
    }]
  },

  {
    path: '/service',
    name: 'Service',
    component: () => import(/* webpackChunkName: "about" */ '../views/Service.vue')
  },
  {
    path: '/news/',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '../views/news.vue'),
    children: [{
      path: '/',
      name: 'Journalism',
      component: () =>
        import( /* webpackChunkName: "about" */ '../views/Journalism.vue'),
    }, {
      path: 'newdetail',
      name: 'newdetail',
      component: () => import(/* webpackChunkName: "about" */ '../views/newdetail.vue'),
      // children: [{
      //   path: '/id:',
      //   name: '01-05-22',
      //   component: () =>
      //     import( /* webpackChunkName: "about" */ '../views/01-05-22.vue'),
      // },]
    }]
  },

  {
    path: '/case',
    name: 'Servicecase',
    component: () => import(/* webpackChunkName: "about" */ '../views/Servicecase.vue')
  },
  {
    path: '/private',
    name: 'PrivateLabel',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivateLabel.vue')
  },
  {
    path: '/contact',
    name: 'Contactus',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contactus.vue')
  },
  {
    path: '/join',
    name: 'Joinus',
    component: () => import(/* webpackChunkName: "about" */ '../views/Joinus.vue')
  },
  {
    path: '/zangJia',
    name: 'zangJia',
    component: () => import(/* webpackChunkName: "about" */ '../views/zangJia24-03.vue')
  }
]

const router = new VueRouter({
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   console.log(to)
  //   console.log(from)
  //   console.log(savedPosition)
  //   return { x: 0, y: 0 }
  // },
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})



export default router
