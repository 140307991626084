<template>
  <div class="Warp">
    <div class="header" :style="{ width: isMobile ? '90%' : '70%' }">
      <div class="logo">
        <img src="../../assets/banner/logo.png" alt="" />
      </div>

      <div
        :class="theLan ? 'navHeader' : 'navHeader2'"
        :style="{ width: isMobile ? '85%' : '85%' }"
      >
        <ul
          v-if="$store.state.isZh"
          :style="{ fontSize: isMobile ? '0.1368rem' : '0.0947rem' }"
        >
          <li v-for="(item, i) in headerArr" :key="i" @click="$router.push(item.path)">
            <p :class="i == id ? 'active' : ''">{{ item.name }}</p>
          </li>
          <!-- <li @click="linkClick">投资者关系</li> -->
          <li v-if="!isMobile">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://www.cninfo.com.cn/new/disclosure/stock?stockCode=003010&orgId=gfbj0834647#latestAnnouncement"
              >{{ $t("header.item7") }}</a
            >
          </li>
          <li v-else>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://webchat.cninfo.com.cn/stock/003010/gfbj0834647/%E8%8B%A5%E7%BE%BD%E8%87%A3"
              >{{ $t("header.item7") }}</a
            >
          </li>
        </ul>
        <!-- 英文版 -->
        <ul v-else :style="{ fontSize: isMobile ? '0.1206rem' : '0.082rem' }">
          <li v-for="(item, i) in headerArr2" :key="i" @click="$router.push(item.path)">
            <p :class="i == id ? 'active' : ''">{{ item.name }}</p>
          </li>
          <!-- <li @click="linkClick">投资者关系</li> -->
          <li v-if="!isMobile">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://www.cninfo.com.cn/new/disclosure/stock?stockCode=003010&orgId=gfbj0834647#latestAnnouncement"
              >{{ $t("header.item7") }}</a
            >
          </li>
          <li v-else>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://webchat.cninfo.com.cn/stock/003010/gfbj0834647/%E8%8B%A5%E7%BE%BD%E8%87%A3"
              >{{ $t("header.item7") }}</a
            >
          </li>
        </ul>
      </div>
      <div class="logo2">
        <img src="../../assets/home/headericon.png" alt="" />
      </div>
      <div class="changeLa" @click="lang_change(2)" v-if="$store.state.isZh">
        <span style="color: #41b1e6">中</span> / EN
      </div>
      <div class="changeLa" @click="lang_change(1)" v-else>
        <span style="color: #41b1e6">EN</span> / 中
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["activeId"],
  data() {
    const that = this;
    return {
      // globalHttpUrl: this.COMMON.httpUrl,
      isMobile: false, //是否移动端
      isZh: true,
      headerArr: [
        {
          path: "/",
          name: "首页",
        },
        {
          path: "/Aboutus",
          name: "关于我们",
        },
        {
          path: "/service",
          name: "服务能力",
        },
        {
          path: "/private",
          name: "自有品牌",
        },
        {
          path: "/news",
          name: "新闻动态",
        },
        {
          path: "/contact",
          name: "联系我们",
        },
        // {
        //   path: "/zangJia",
        //   name: "新页面",
        // },
      ],
      headerArr2: [
        {
          path: "/",
          name: "Home Page",
        },
        {
          path: "/Aboutus",
          name: "About Us",
        },
        {
          path: "/service",
          name: "Service Capability",
        },
        {
          path: "/private",
          name: "Private Brand",
        },
        {
          path: "/news",
          name: "News",
        },
        {
          path: "/contact",
          name: "Contact Us",
        },
        // {
        //   path: "/zangJia",
        //   name: "new Page",
        // },
      ],
      id: this.activeId,
    };
  },
  watch: {
    activeId(newVal) {
      this.id = newVal;
    },
  },
  computed: {
    theLan() {
      // 计算数值的总和
      return this.$store.state.isZh;
    },
  },
  mounted() {
    console.log(this.activeId, "activeId");

    //判断是pc还是移动端
    this.isMobile = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
  },
  methods: {
    linkClick() {
      // console.log(1111)
      // window.open('http://www.cninfo.com.cn/new/disclosure/stock?stockCode=003010&orgId=gfbj0834647#latestAnnouncement','_blank')
    },
    // 改变语言
    lang_change(value) {
      if (value == 1) {
        console.log("zh");
        this.$i18n.locale = "zh";
        this.set_cookie("lang", "zh", 6); // 第一个参数是名字，第二个参数是当前的语言，第三个参数是表示 cookie 过期时间，可能是6（天）
        this.isZh = true;
        // window.location.reload()
      } else {
        console.log("en");
        this.$i18n.locale = "en";
        this.set_cookie("lang", "en", 6);
        this.isZh = false;

        // window.location.reload()
      }
      this.$store.commit("change", value);
    },
  },
};
</script>

<style scoped lang="less">
.Warp {
  width: 100%;
  border-bottom: thin solid #9e9f9f;
}

.header {
  // width: 1200px;
  width: 70%;
  min-width: 1000px;

  margin: 0 auto;
  display: flex;
  padding: 0 20px;
  box-sizing: border-box;

  .logo {
    line-height: 0.3263rem;
    margin: 0 20px 0 0;

    img {
      width: 0.1579rem;
      height: 0.2316rem;
      vertical-align: middle;
    }
  }

  .logo2 {
    line-height: 0.3263rem;
    // margin: 0 20px 0 0;

    img {
      width: 0.2684rem;
      height: 0.1316rem;
      vertical-align: middle;
    }
  }

  .changeLa {
    color: #fff;
    line-height: 0.3263rem;
    margin-left: 12px;
    font-size: 14px;
    cursor: pointer;
  }

  .navHeader {
    width: 85%;
    max-width: 1120px;
    // height: .3263rem;
    // font-size: 16px;
    padding: 0 7%;

    ul {
      width: 100%;
      display: flex;
      // height: .3263rem;
      line-height: 0.3263rem;
      cursor: pointer;
      // font-size: .0947rem;

      // font-family: 'PF';
      // font-weight: 500;
      li {
        color: #fff;
        flex: 1;
        overflow: hidden;
        height: 0.3263rem;
        text-align: center;

        a {
          color: #fff;
        }

        a:hover {
          // background-color: #009bdd;
          color: #41b1e6;
        }
      }

      li:hover {
        // background-color: #009bdd;
        color: #41b1e6;
      }

      .active {
        display: inline-block;
        // background-color: #009bdd;
        color: #41b1e6;
        border-bottom: thin solid #41b1e6;
        height: 70%;
      }
    }
  }

  .navHeader2 {
    width: 85%;
    max-width: 1120px;
    // height: .3263rem;
    // font-size: 16px;
    padding: 0 4%;

    ul {
      width: 100%;
      display: flex;
      // height: .3263rem;
      line-height: 0.3263rem;
      cursor: pointer;
      // font-size: .0947rem;

      // font-family: 'PF';
      // font-weight: 500;
      li {
        color: #fff;
        // flex: 1;
        padding: 0 20px;
        overflow: hidden;
        height: 0.3263rem;
        // font-size: 0.082rem;
        white-space: nowrap;
        text-align: center;

        a {
          color: #fff;
        }

        a:hover {
          // background-color: #009bdd;
          color: #41b1e6;
        }
      }

      li:hover {
        // background-color: #009bdd;
        color: #41b1e6;
      }

      .active {
        display: inline-block;
        // background-color: #009bdd;
        color: #41b1e6;
        border-bottom: thin solid #41b1e6;
        height: 70%;
      }
    }
  }
}
</style>
