<template>
  <div class="brand-con">
    <!-- <img src="../../assets/home/1-首页_06.jpg" alt="" /> -->
    <!-- <div style="position:fixed;top:50%;
    left: 50%;
    z-index: 99;
    font-size: 40px;"> Top:{{Top}}</div> -->

    <div :class="isMobile ? 'boxMobile' : 'box'">
      <div class="news-title">
        <p>{{ $t("BrandCon.smallTitle") }}</p>
        <p>{{ $t("BrandCon.title") }} </p>
        <div class="more">
          <img :style="{width: isMobile? '.8rem' : '.5421rem'}" @click="$router.push('/service')" src="../../assets/about/More.png" alt="" />
        </div>
      </div>

      <div class="box-son">
        <div class="logWarp">
          <div class="logBox" v-for="(item, i) in logoItem" :key="i">
            <img
              :style="{ width: getImgWidth(item.logo) / 152 + 'rem', transform: 'scale(0.6)', opacity: item.show ? '1' : '0' }"
              :src="item.logo" alt="">
            <!-- <img
              :style="{ width: getImgWidth(datas[index].logo) / 192 + 'rem', transform: i == 8 ? 'scale(0.6) translateX(-0.1053rem)' : 'scale(0.6)', opacity: item.show ? '1' : '0' }"
              :src="item.logo" alt=""> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { findLogos } from "@/api/index.js";
export default {

  data() {
    return {
      logoItem: [
        {
          serial_number: 0,
          logo: '',
          show: false,
        },
      ],
      Top: '',
      isMobile: false,
      queryParam: {              //查询参数
        page_no: 1,
        page_size: 300,
      },
      logoArr: [
        {
          "url": require('../../assets/cooperation/1-1.png'),
          "width": "1.225rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/2-1.png'),
          "width": ".675rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/3-1.png'),
          "width": ".4083rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/4-1.png'),
          "width": "0.8833rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/5-1.png'),
          "width": ".4333rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/6-1.png'),
          "width": ".4083rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/1-2.png'),
          "width": ".8417rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/2-2.png'),
          "width": "1.0583rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/3-2.png'),
          "width": ".3333rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/4-2.png'),
          "width": ".4333rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/5-2.png'),
          "width": ".9833rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/6-2.png'),
          "width": ".7583rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/1-3.png'),
          "width": ".925rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/2-3.png'),
          "width": ".4167rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/3-3.png'),
          "width": "1.1667rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/4-3.png'),
          "width": ".4667rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/5-3.png'),
          "width": " .775rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/6-3.png'),
          "width": ".8083rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/1-4.png'),
          "width": ".525rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/2-4.png'),
          "width": ".4833rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/3-4.png'),
          "width": "1.083rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/4-4.png'),
          "width": ".275rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/5-4.png'),
          "width": " .8583rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/6-4.png'),
          "width": "  .6917rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/1-5.png'),
          "width": " .8rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/2-5.png'),
          "width": " .6333rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/3-5.png'),
          "width": " .6917rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/4-5.png'),
          "width": " .6417rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/5-5.png'),
          "width": " .3917rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/6-5.png'),
          "width": " .7rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/1-6.png'),
          "width": " .3917rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/2-6.png'),
          "width": " .65rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/3-6.png'),
          "width": " .4833rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/4-6.png'),
          "width": " .6167rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/5-6.png'),
          "width": " .8583rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/6-6.png'),
          "width": " .925rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/1-7.png'),
          "width": " .8667rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/2-7.png'),
          "width": " .9833rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/3-7.png'),
          "width": " .925rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/4-7.png'),
          "width": " .775rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/5-7.png'),
          "width": " .7rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/6-7.png'),
          "width": " .7833rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/1-8.png'),
          "width": " .6833rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/2-8.png'),
          "width": ".9417rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/3-8.png'),
          "width": " .8083rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/4-8.png'),
          "width": " .6083rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/5-8.png'),
          "width": " .5583rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/6-8.png'),
          "width": " .8917rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/1-9.png'),
          "width": " .2917rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/2-9.png'),
          "width": ".7833rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/3-9.png'),
          "width": " 1.0083rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/4-9.png'),
          "width": " .6333rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/5-9.png'),
          "width": " .7rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/6-9.png'),
          "width": " .4167rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/1-10.png'),
          "width": " .425rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/2-10.png'),
          "width": ".5667rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/3-10.png'),
          "width": " .5333rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/4-10.png'),
          "width": " .5rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/5-10.png'),
          "width": " .5583rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/6-10.png'),
          "width": " .6083rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/1-11.png'),
          "width": " .45rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/2-11.png'),
          "width": ".425rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/3-11.png'),
          "width": " .825rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/4-11.png'),
          "width": " .75rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/5-11.png'),
          "width": " 1.1833rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/6-11.png'),
          "width": " .7667rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/1-12.png'),
          "width": " .4083rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/2-12.png'),
          "width": ".3333rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/3-12.png'),
          "width": " .5583rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/4-12.png'),
          "width": " .8083rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/5-12.png'),
          "width": " 1.033rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/6-12.png'),
          "width": " .7167rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/1-13.png'),
          "width": " .725rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/2-13.png'),
          "width": ".825rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/3-13.png'),
          "width": " .925rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/4-13.png'),
          "width": " .8083rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/5-13.png'),
          "width": " .6167rem",
          "show": false
        },
        {
          "url": require('../../assets/cooperation/6-13.png'),
          "width": " .3333rem",
          "show": false
        },
      ],
    };
  },
  mounted() {
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    if (this.isMobile) {
      console.log('1111111', this.logoItem);
      setTimeout(() => {
        this.logoShow();
        console.log(this.logoItem);
      }, 2000);
      // this.logoShow();
      
    }
    window.addEventListener("scroll", this.handleScroll, true)
  },
  created() {
    this.getList();
  },
  methods: {
    // 判断PC端还是移动端
    // isMobile() {
    //   let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    //   return flag;
    // },

    // 获取数据
    getList() {
      findLogos(this.queryParam).then((res) => {
        // console.log(res);
        this.logoItem = res.data.rows.sort(function (a, b) {
          return a.serial_number - b.serial_number
        })
        for (let iterator of this.logoItem) {
          iterator.show = false
        }
        // console.log(this.logoItem);
      })
    },

    // 获取图片真实宽高
    getImgWidth(url) {
      let img = new Image()
      img.src = url
      img.onload = function () {
        // res = {
        //   width: img.width,
        // }
        // console.log('width', img.width);
      }
      return img.width;
    },

    handleScroll() {
      this.Top = document.documentElement.scrollTop
      let maxHeight = document.body.clientHeight
      let scale = 2000 / 4278
      let Mheight = scale * maxHeight
      if (this.Top >= Mheight) this.logoShow();
    },

    //显示图片
    logoShow() {
      // console.log("222");
      for (let i = 0; i < this.logoItem.length; i++) {
        setTimeout(() => {
          this.logoItem[i].show = true
          this.logoItem = [...this.logoItem]
          // this.logoItem = JSON.parse(JSON.stringify(this.logoItem))
          // this.$set(this.logoItem[i],"show", true)
          // console.log(this.logoItem[i].logoShow);
        }, i * 80)
      }
      // console.log(this.logoItem);
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  }
};
</script>

<style scoped lang="less">
.brand-con {
  height: 7.02193rem;
  width: 100%;
  position: relative;

  &>img {
    width: 100%;
  }

  .box {
    width: 63%;
    // max-width: 1200px;;
    height: 100%;
    margin: 0 auto;
    // background-color: rgba(200, 200, 200, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;

    .logBox {
      transition: 1.5s;
      border-left: thin solid #cdcdcd;
      width: 16.6%;
      height: .389474rem;
      line-height: .389474rem;
      text-align: center;

      img {
        transition: 1.5s;
      }
    }

    .logoShow {
      opacity: 1;
      transition: 1.5s;
    }

    // 原有代码
    // .logBox:nth-child(1),
    // .logBox:nth-child(7),
    // .logBox:nth-child(13),
    // .logBox:nth-child(19),
    // .logBox:nth-child(25),
    // .logBox:nth-child(31),
    // .logBox:nth-child(37),
    // .logBox:nth-child(43),
    // .logBox:nth-child(49),
    // .logBox:nth-child(55),
    // .logBox:nth-child(61),
    // .logBox:nth-child(67),
    // .logBox:nth-child(73) {
    //   border-left: none;
    // }

    .logBox:nth-child(6n+1) {
      border-left: none;
    }

    // .logBox:nth-child(9) {
    //   img {
    //     margin-left: .1211rem;
    //   }

    // }

    .news-title {
      width: 90%;
      margin: 2% auto 5%;
      position: relative;

      p:nth-child(2) {
        font-size: .2105rem;
        // font-size: 45px;
        // font-weight: 500;
        // color: rgb(62, 62, 62);
        color: #444444;
      }

      p:nth-child(1) {
        font-size: .1053rem;
        // font-size: 16px;
        // color: rgb(163, 163, 163);
        color: #b8b8b8;
        // margin-top:
      }

      .more {
        width: 11%;
        position: absolute;
        right: 0%;
        // top: 10%;
        bottom: 0%;
        cursor: pointer;

        img {
          width: .5421rem;
        }
      }
    }

    // .more {
    //   position: absolute;
    //   right: 9%;
    //   top: 15%;
    //   img {
    //     cursor: pointer;
    //   }
    // }
    .box-son {
      width: 100%;
      // text-align: center;
      margin: 2% auto 0;
    }

    .logWarp {
      border-left: thin solid #ffffff;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      padding: 0 0 .526316rem 0;
      width: 100%;
    }

    // .box-son {
    //   width: 90%;
    //   // width: 1142px;
    //   height: 60%;
    //   margin: 2% auto 0;
    //   ul {
    //     width: 100%;
    //     height: 100%;
    //     display: flex;
    //     flex-wrap: wrap;
    //     text-align: center;
    //     justify-content: space-between;
    //     background-color: #fff;
    //     overflow: hidden;
    //     border-top: 1px solid #e0e0e0;
    //     border-left: 1px solid #e0e0e0;

    //     // height:
    //     li {
    //       width: 16.6%;

    //       // height: 20%;
    //       // text-align: center;
    //       position: relative;
    //       border-right: 1px solid #e0e0e0;
    //       border-bottom: 1px solid #e0e0e0;
    //       overflow: hidden;
    //       text-align: center;
    //       img {
    //         width: 70%;
    //         display: block;
    //         position: absolute;
    //         left: 0;
    //         top: 0;
    //         right: 0;
    //         bottom: 0;
    //         margin: auto;
    //       }
    //     }
    //     li:hover img {
    //       transform: scale(1.3);
    //     }
    //   }
    // }
  }

  // 移动端样式
  .boxMobile {
    width: 100%;
    // max-width: 1200px;;
    height: 100%;
    margin: 0 auto;
    // background-color: rgba(200, 200, 200, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;

    .logBox {
      transition: 1.5s;
      border-left: thin solid #cdcdcd;
      width: 16.6%;
      height: .389474rem;
      line-height: .389474rem;
      text-align: center;

      img {
        transition: 1.5s;
      }
    }

    .logoShow {
      opacity: 1;
      transition: 1.5s;
    }

    .logBox:nth-child(6n+1) {
      border-left: none;
    }


    .news-title {
      width: 80%;
      margin: 2% auto 5%;
      position: relative;

      p:nth-child(2) {
        font-size: .2105rem;
        // font-size: 45px;
        // font-weight: 500;
        // color: rgb(62, 62, 62);
        color: #444444;
      }

      p:nth-child(1) {
        font-size: .1053rem;
        // font-size: 16px;
        // color: rgb(163, 163, 163);
        color: #b8b8b8;
        // margin-top:
      }

      .more {
        width: 11%;
        position: absolute;
        right: 0%;
        // top: 10%;
        bottom: 0%;
        cursor: pointer;

        img {
          width: .5421rem;
        }
      }
    }

    .box-son {
      width: 86%;
      margin: 2% auto 0;
    }

    .logWarp {
      border-left: thin solid #ffffff;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      padding: 0 0 .526316rem 0;
      width: 100%;
    }

  }
}
</style>