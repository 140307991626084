

<template>
  <!-- 首页 -->
  <div class="home">
    <div class="top">
      <Header :activeId="activeId" />
    </div>
    <div class="home-box">
      <div>
        <Banner />
      </div>

      <div class="home-about">
        <div class="bgBlank"></div>
        <img src="../assets/home/about_bg.png" alt="" :style="{ marginTop: isMobile ? '120px' : '0' }" />
        <div class="home-about-son">
          <div class="guanyu" :style="{ width: isMobile ? '88.9%' : '63%' }">
            <div class="guanyu-son">
              <p>{{ $t("home.aboutas") }}</p>
              <p>{{ $t("home.aboutasTl") }}</p>
              <div class="guanyu-son-text">
                <p>{{ $t("home.aboutasTextTl") }}</p>
                <p>
                  {{ $t("home.aboutasText") }}
                </p>
              </div>
            </div>

            <div class="more">
              <img :style="{ width: isMobile ? '.8rem' : '.5421rem' }" @click="$router.push('/aboutus')"
                src="../assets/about/More.png" alt="" />
            </div>
          </div>

        </div>
        <div class="home-about-two">
          <ServiceCon />
        </div>
        <JournalismCon :Ptitle="title" :Pmessage="message" :PArr="Arr" />
        <div ref="scroll">
          <BrandCon />
        </div>
        <div>
          <Bottom />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import Bottom from "@/components/bottomNav/Bottom.vue";
import Banner from "@/components/banner/Banner.vue";
import ServiceCon from "@/components/home/ServiceCon.vue";
import JournalismCon from "@/components/home/JournalismCon.vue";
import BrandCon from "@/components/home/BrandCon.vue";

export default {
  name: "Home",
  components: {
    // HelloWorld,
    Header,
    Bottom,
    Banner,
    ServiceCon,
    JournalismCon,
    BrandCon,

  },
  data() {
    return {
      // globalHttpUrl: this.COMMON.httpUrl,
      message: 1,
      Arr: 1,
      title: 1,
      activeId: '0',
      banner: "",
      Top: "",
      isMobile: false,                //是否移动端
    };
  },
  computed: {
    theLan() {
      // 计算数值的总和
      return this.$store.state.isZh
    },
  },
  mounted() {
    //判断是pc还是移动端
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  },

  methods: {

  },
  created() {
    // let _this = this;
    // this.axios.get(this.globalHttpUrl + "findBanner").then((res) => {
    //   // this.searchHint = res.data.keywords;
    //   console.log("findBanner", res);
    // });
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  }

};
</script>

<style scoped lang="less">
.home {
  // width: 1920px;
  width: 100%;
  // min-width: 1920px;
  position: relative;

  .bgBlank {
    height: 3.857895rem;
  }

  .top {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    // background-color: rgba(0, 0, 0, 0);
    color: #444444;
  }

  .home-box {
    width: 100%;
    // background: url("../assets/logo/背景.png");
    background-size: cover;
    background-repeat: no-repeat;

    .home-about {
      width: 100%;
      position: relative;

      &>img {
        width: 100%;
      }

      .home-about-son {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;

        .guanyu {
          // width: 63%;
          // height: 100%;
          // background: pink;
          padding: 10px;
          margin: 0 auto;
          position: relative;

          .guanyu-son {
            width: 90%;
            // height: 70%;
            // background-color: skyblue;
            margin: 12% auto;

            p:nth-child(2) {
              font-size: .2105rem;
              // font-size: 45px;
              // font-weight: 500;
              // color: rgb(62, 62, 62);
              color: #444444;
            }

            p:nth-child(1) {
              font-size: .1053rem;
              // font-size: 16px;
              // color:#b8b8b8;
              color: #b8b8b8;
              // margin-top:
            }

            .guanyu-son-text {
              margin-top: 0.263158rem;
              // color: rgb(95, 95, 95);
              color: #444444;

              p:first-child {
                color: #444444;
                font-size: 0.1368rem;
                line-height: .1895rem;
                // font-size: 24px;
                // line-height: 28px;
              }

              p:last-child {
                color: #b8b8b8;
                line-height: .1684rem;
                // line-height: 26px;
                margin: 2% 0 0 0;
                // font-weight: 600;
                // margin-bottom: .184211rem;
                font-size: .1053rem;
                // font-size: 16px;
              }
            }
          }

          .more {
            width: 10%;
            position: absolute;
            right: 6%;
            top: 45%;

            img {
              width: .5421rem;
            }
          }
        }
      }

      .home-about-two {
        width: 100%;
        position: absolute;
        left: 0;
        top: 2.036842rem;
      }

    }
  }
}
</style>