<template>
  <div class="service">
    <!-- <img src="../../assets/home/1-首页_04.jpg" alt="" /> -->
    <!-- <img src="../../assets/home/about_bg.png" class="Bgimg" alt="" /> -->
    <div class="box" :style="{ width: isMobile ? '88.9%' : '63%' }">
      <div class="service-title" :style="{ marginTop: isMobile ? '85px' : '' }">
        <p>{{ $t("Capability.smallTitle") }}</p>
        <p>{{ $t("Capability.title") }}</p>

        <div class="more">
          <img :style="{ width: isMobile ? '.8rem' : '.5421rem' }" @click="$router.push('/service')"
            src="../../assets/about/More.png" alt="" />
        </div>
      </div>

      <div class="box-son">
        <ul :class="theLan ? 'icon-box' : 'icon-box2'" @click="$router.push('/service')">
          <li>
            <div>
              <img class="aa" src="../../assets/logo/change/1-2.png" />
              <img class="bb" src="../../assets/logo/change/1-1.png" />
            </div>
            <div>{{ $t("Capability.itemText1") }}</div>
          </li>
          <li>
            <div>
              <img class="aa" src="../../assets/logo/change/2-2.png" />
              <img class="bb" src="../../assets/logo/change/2-1.png" />
            </div>
            <div>{{ $t("Capability.itemText2") }}</div>
          </li>
          <li>
            <div>
              <img class="aa" src="../../assets/logo/change/3-2.png" />
              <img class="bb" src="../../assets/logo/change/3-1.png" />
            </div>
            <div>{{ $t("Capability.itemText3") }}</div>
          </li>
          <li>
            <div>
              <img class="aa" src="../../assets/logo/change/4-2.png" />
              <img class="bb" src="../../assets/logo/change/4-1.png" />
            </div>
            <div>{{ $t("Capability.itemText4") }}</div>
          </li>
          <li>
            <div>
              <img class="aa" src="../../assets/logo/change/5-2.png" />
              <img class="bb" src="../../assets/logo/change/5-1.png" />
            </div>
            <div>{{ $t("Capability.itemText5") }}</div>
          </li>
          <li>
            <div>
              <img class="aa" src="../../assets/logo/change/6-2.png" />
              <img class="bb" src="../../assets/logo/change/6-1.png" />
            </div>
            <div>{{ $t("Capability.itemText6") }}</div>
          </li>
          <li>
            <div>
              <img class="aa" src="../../assets/logo/change/7-2.png" />
              <img class="bb" src="../../assets/logo/change/7-1.png" />
            </div>
            <div>{{ $t("Capability.itemText7") }}</div>
          </li>
          <li>
            <div>
              <img class="aa" src="../../assets/logo/change/8-2.png" />
              <img class="bb" src="../../assets/logo/change/8-1.png" />
            </div>
            <div>{{ $t("Capability.itemText8") }}</div>
          </li>
          <li>
            <div>
              <img class="aa" src="../../assets/logo/change/9-2.png" />
              <img class="bb" src="../../assets/logo/change/9-1.png" />
            </div>
            <div>{{ $t("Capability.itemText9") }}</div>
          </li>
          <li>
            <div>
              <img class="aa" src="../../assets/logo/change/10-2.png" />
              <img class="bb" src="../../assets/logo/change/10-1.png" />
            </div>
            <div>{{ $t("Capability.itemText10") }}</div>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,                //是否移动端
      // fontSize1: 18,
    };
  },
  mounted() {
    //判断是pc还是移动端
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);

  },
  computed: {
    theLan() {
      // 计算数值的总和
      return this.$store.state.isZh
    },
  },
};
</script>

<style scoped lang="less">
.service {
  // width: 1920px;
  width: 100%;
  // min-height: 713px;
  position: relative;

  // > img {
  //   width: 100%;
  // }

  .box {
    // width: 63%;
    // height: 100%;
    // background-color: rgba(200, 200, 200, 0.5);
    padding: 10px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    .service-title {
      width: 90%;
      margin: 5% auto;
      position: relative;

      p:nth-child(2) {
        font-size: .2105rem;
        // font-size: 45px;
        // font-weight: 500;
        // color: rgb(62, 62, 62);
        color: #444444;
      }

      p:nth-child(1) {
        font-size: .1053rem;
        // font-size: 16px;
        // color:#b8b8b8;
        color: #b8b8b8;
        // margin-top:
      }

      .more {
        width: 11%;
        position: absolute;
        right: 0%;
        // top: 10%;
        bottom: 0%;
        cursor: pointer;

        img {
          width: .5421rem;
        }
      }
    }

    .box-son {
      width: 90%;
      // height: 70%;
      background-color: pink;
      margin: 2% auto 3%;
      white-space: pre-wrap;

      .icon-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        text-align: center;
        // overflow: hidden;
        font-size: .1053rem;
        // font-size: 24px;

        li {
          width: 20%;
          // height: 50%;
          border: .010526rem solid #fff;
          box-sizing: border-box;
          background-color: #f0f0f0;
          // color: #6b6b6b;
          color: #444444;

          // font-size: 18px;
          img {
            width: 30%;
            // height: 30%;
          }

          .aa {
            display: inline-block;
          }

          .bb {
            display: none;
          }

          &:hover .aa {
            display: none;
          }

          &:hover .bb {
            display: inline-block;
          }

          div {
            padding: 0 .026316rem;
          }

          div:first-child {
            margin: 15% 0 12% 0;
          }

          div:last-child {
            margin-bottom: 10%;
            line-height: 150%;
          }
        }

        li:hover {
          background-color: #019ede;
          color: #fff;
        }
      }

      .icon-box2 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        text-align: center;
        // overflow: hidden;
        font-size: .1053rem;
        // font-size: 24px;

        li {
          width: 20%;
          // height: 50%;
          border: .010526rem solid #fff;
          box-sizing: border-box;
          background-color: #f0f0f0;
          // color: #6b6b6b;
          color: #444444;

          // font-size: 18px;
          img {
            width: 30%;
            // height: 30%;
          }

          .aa {
            display: inline-block;
          }

          .bb {
            display: none;
          }

          &:hover .aa {
            display: none;
          }

          &:hover .bb {
            display: inline-block;
          }

          div {
            padding: 0 .026316rem;
          }

          div:first-child {
            margin: 15% 0 12% 0;
          }

          div:last-child {
            margin-bottom: 10%;
            line-height: 150%;
            font-size: 12px;
          }
        }

        li:hover {
          background-color: #019ede;
          color: #fff;
        }
      }
    }
  }
}
</style>