】<template>
  <div id="app">
    <div class="app">
      <!-- <keep-alive> -->
        <router-view :key="$route.fullPath"/>
      <!-- </keep-alive> -->
    </div>
  </div>
</template>

<script>
export default({
  methods: {
    placeholderPic() {
      window.onresize = () => {
        return (() => {
          const screenWidth = document.body.clientWidth;
          document.documentElement.style.fontSize = screenWidth / 10 + "px";
        })();
      };
    },
    ScrollFun(){
      console.log(1)
    }
  },
  mounted() {},
});
</script>


<style scoped lang="less">
@import './assets/font/font.css';

#app {
  font-family: 'HarmonyOS_B','HarmonyOS';
  // font-family: 'Microsoft YaHei', 'Arial Narrow', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  // width: 100%;
  max-width: 1920px;
  min-width: 1200px;
  margin: auto;
}
</style>
