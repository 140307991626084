module.exports = {
    header: {
        item1: "Home Page",
        item2: "About Us",
        item3: "Service Capability",
        item4: "Private Brand",
        item5: "News",
        item6: "Contact Us",
        item7: "Investor Relations",
    },
    Bottom: {
        item1: "B2B Cooperation",
        item2: "Cooperation with the Media",
        item3: "CV Submission",
        item4: "Report & Complaint",
        item5: "",
    },
    login: {
        loginhello: "Hello",
        loginTitle: "Welcome Sign In",
    },
    home: {
        aboutas: "",
        aboutasTl: "ABOUT US",
        aboutasTextTl: "RYC, a global leader in digital management for consumer brands.",
        aboutasText: "Founded in 2011 in Guangzhou, with the mission of “connecting consumer brands across the globe with Chinese customers”, RYC is seeking to fuel the growth of brands throughout their lifecycle with our digital expertise in integrated marketing and omnichannel operation.",
    },
    Capability: {
        smallTitle: "",
        title: "SERVICE CAPABILITY",
        itemText1: "Omnichannel \n Operation",
        itemText2: "Digital Marketing",
        itemText3: "Customer Relationship \n Management",
        itemText4: "Visual Design",
        itemText5: "Recommendation via \n Content Creation",
        itemText6: "Live Streaming",
        itemText7: "Digitalization",
        itemText8: "Channel \n Distribution",
        itemText9: "Customer Service",
        itemText10: "Supply \n Chain Management",
    },
    News: {
        smallTitle: "",
        title: "NEWS AND TRENDS",
    },
    Aboutus: {
        topsmallTitle: "",
        topTitle: "ABOUT US",
        smallTitle: "",
        title: "THE ENTERPRISE CULTURE"
    },
    BrandCon: {
        smallTitle: "",
        title: "COOPERATIVE BRAND",
    },
    Introduce: {
        smallTitle: "",
        title: "BRIEF INTRODUCTION",
        p1: "RYC was founded in Guangzhou in 2011 as a global leader in digital management for consumer brands. With the mission of “connecting consumer brands across the globe with Chinese customers”, we commit ourselves to the growth of brands throughout their entire lifecycle with our digital expertise in integrated marketing and omnichannel operation.",
        p2: "On September 25th, 2020, RYC was officially listed on Shenzhen Stock Exchange (SSE), making us the first agency listed on the main board of SSE (Stock Code: 003010.SZ). Based on the development strategy featuring “Guangzhou + Shanghai”dual-headquarter growth, we managed to achieve an annual GMV of more than 10 billion RMB with an exceptionally high annual growth rate of over 50% for 5 consecutive years. Relying on a team of more than 1,000 employees, we serve clients in more than 30 countries and regions, providing best-in-class digital services to over 100 high-profile brands both home and abroad including P&G, Bayer and Mengniu.",
        p3: "In addition to our leading position in categories such as healthcare products, mother and baby care, beauty, personal care.ood and beverage, we have been expanding into high-potential categories such as pet products and healthcare. We striveio provide brands with a ful range of cigital solutions,including retined omnichanne operation, digital marketing,CustomeRelationship Management, information technology, customer service and supply chain management. Committed to valuecreation by shouldering our due responsibility and embracing changes, we always focus on service provision and digitacapacity-building to drive overall value improvement for the brand and help create high quality life for Chinese consumern the new era of consumption.",
        mission: "MISSION",
        missionText: "Connect Consumer Brands Across the Globe with Chinese Customers.",
        vision: "VISION",
        visionText: "Help create high quality life for Chinese consumers in the new era of consumption.",
        value: "VALUE",
        valueText: "Responsible, Adaptable, Value-added.",
    },
    Service: {
        topsmallTitle: "",
        topTitle: "SERVICE CAPABILITY",
        smallTitle: "",
        title: "SERVICE CAPABILITY",
        showCasesmall: "",
        showCase: "SERVICE SHOWCASE",
        item1: "Omnichannel Operation",
        item2: "Digital Marketing",
        item3: "Customer Relationship Management",
        item4: "Live Streaming Service",
        item5: "Recommendation via \n Content Creation",
        item6: "Digitalization",
        item7: "Visual Design",
        item8: "Channel Distribution",
        item9: "Customer Service",
        item10: "Supply ChainManagement",
    },
    privateLabel: {
        smallTitle: "",
        title: "PRIVATE BRAND",
    },
    theNews: {
        smallTitle: "",
        title: "News",
        smallTitle1: "",
        smallTitle2: "",
        title2: "",
    },
    Contactus: {
        smallTitle: "",
        title: "CONTACT US",
        titleson1: "B2B Cooperation",
        titleson2: "CV Submission",
        smallintrotitle: "",
        introtitle: "CLASS OF 2024 CAMPUS RECRUITMENT JOB INTRODUCTION",
        PolarisText: 'The Polaris Program aims to help management trainees grow rapidly and cultivate high potential starlets in all positions through the "Super Star Cultivation Program", featuring internal job rotation, real task practice, one-on-one tutoring and professional training, etc.',
    },
    Address: {
        smallTitle: "",
        title: "ADDRESS",
        guangzhou: "Address in \n GuangZhou",
        guangzhou1: "32nd floor, Block G, Gaode \n Land Winter Plaza, Tianhe District, Guangzhou",
        guangzhou2: "25/F, Poly Zhongyu Building,\n No. 319 Dashadong, Huangpu \nDistrict, Guangzhou",
        guangzhou3: "Room 2407-10, Global City \nPlaza, No. 68 Huacheng Avenue,\n Tianhe District, Guangzhou",
        shanghai: "Address in Shanghai",
        shanghai1: " 5/F, CITIC Plaza, No. 859 North Sichuan Road, Hongkou District, Shanghai",
        NewZealand: "Address in New Zealand",
    },
}