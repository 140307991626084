<template>
  <div class="banner">
    <!-- <el-carousel :interval="5000" arrow="always" :height="`${width}px`">
      <el-carousel-item >
        <img loading="lazy" src="../../assets/banner/1-1.jpg" alt="" />
      </el-carousel-item>
       <el-carousel-item >
        <img loading="lazy" src="../../assets/banner/1-2.jpg" alt="" />
      </el-carousel-item>
       <el-carousel-item >
        <img loading="lazy" src="../../assets/banner/1-3.jpg" alt="" />
      </el-carousel-item>
    </el-carousel> -->
    <img class="anime" loading="lazy"
      src="//img20.360buyimg.com/zx/jfs/t1/178367/35/35350/25171/64c8d873F547f0701/8fad07a2c2bb0cd8.jpg" alt="" />
    <img class="logo" loading="lazy" src="../../assets/banner/Banner_logo.png" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      screenWidth: 0,
      width: 0,
    };
  },
  created() {
    // this.screenWidth = document.body.clientWidth;
    // console.log(this.screenWidth)
    // this.width = this.screenWidth * 856 / 1920
    // console.log("this.width",this.width)
  },

  mounted() {
    this.screenWidth = document.body.clientWidth;
    // this.screenHeight = document.body.clientHeight;
    if (this.screenWidth < 1200) {
      // return
      this.screenWidth = 1200;
    }
    if (this.screenWidth > 1920) {
      this.screenWidth = 1920;
    }
    this.width = (this.screenWidth * 800) / 1920;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        // this.screenHeight = document.body.clientHeight;
        if (this.screenWidth < 1200) {
          // return
          this.screenWidth = 1200;
        }
        if (this.screenWidth > 1920) {
          this.screenWidth = 1920;
        }
        this.width = (this.screenWidth * 800) / 1920;
        // console.log(this.width);
      })();
    };
  },
};
</script>

<style scoped lang="less">
.banner {
  overflow: hidden;
  position: relative;
  width: 100%;

  // height: 856px;
  img {
    width: 100%;
  }

  .logo {
    height: .494737rem;
    width: 3.584211rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .anime {
    transform: translate(0, 0);
    width: 100%;
    // animation: bigAnimate 10s linear  infinite alternate;
    animation: lateAnimate 10s linear infinite alternate;
  }

  @keyframes bigAnimate {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.5);
    }
  }

  @-webkit-keyframes bigAnimate {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.5);
    }
  }

  @keyframes lateAnimate {
    from {
      transform: scale(1) translate(0, 0);
    }

    to {
      transform: scale(1.8) translate(1.052632rem, .105263rem);
    }
  }

  @-webkit-keyframes lateAnimate {
    from {
      transform: scale(1) translate(0, 0);
    }

    to {
      transform: scale(1.8) translate(1.052632rem, .105263rem);
    }
  }
}

.el-carousel__item h3 {
  // color: #475669;
  color: #444444;
  font-size: .0947rem;
  opacity: 0.75;
  // line-height: 300px;
  margin: 0;
}</style>